<template>
  <div class="health-vip">
    <div style="color: #111; font-size: 0.16rem">会员卡</div>
    <div style="color: #999; font-size: 0.11rem; line-height: 0.2rem">
      专享超值优惠，为你省更多
    </div>
    <div class="health-vip-content">
      <template v-if="memberCards.length">
        <card-item
          v-for="(item, index) in memberCards"
          :key="index"
          :value="item"
        ></card-item>
      </template>
      <div v-else style="text-align: center; padding: 0.5rem 0">暂无卡片</div>
    </div>
  </div>
</template>
<script>
import CardItem from "@/components/CardItem";
import { getMemberCards } from "@/service";
export default {
  name: "HealthVip",
  components: { CardItem },
  data() {
    return {
      memberCards: [],
    };
  },
  mounted() {
    this.getMemberCards();
  },
  methods: {
    async getMemberCards() {
      const merchantId = this.$store.state.merchantId;
      const { data: list } = await getMemberCards(merchantId);
      this.memberCards = list;
    },
  },
};
</script>
<style lang="scss" scoped>
.health-vip {
  padding: 0.15rem 0.14rem 0.14rem;
  font-weight: 500;
  .health-vip-content {
    margin-top: 0.15rem;
  }
}
</style>
