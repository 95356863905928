<template>
  <div class="card-item" @click="goCardViewer">
    <div class="card-item-left">
      <card :value="value"></card>
    </div>
    <div class="card-item-right">
      <div class="card-item-right-1">{{ value.skuName }}</div>
      <div class="card-item-right-2">{{ value.cmt || "--" }}</div>
      <div class="card-item-right-3">
        <a-button type="primary" shape="round">立即开通</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card";
export default {
  name: "CardItem",
  components: { Card },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    goCardViewer() {
      const { skuNo } = this.value;
      this.$router.push({ name: "CardViewer", params: { skuNo } });
    },
  },
};
</script>
<style lang="scss" scoped>
.card-item {
  display: flex;
  margin-bottom: 0.15rem;
  .card-item-left {
    width: 1.11rem;
  }
  .card-item-right {
    width: calc(100% - 1.21rem);
    margin-left: 0.1rem;
    .card-item-right-1 {
      font-size: 0.13rem;
      color: #111;
    }
    .card-item-right-2 {
      font-size: 0.08rem;
      color: #999;
      height: 28px;
      line-height: 13px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .card-item-right-3 {
      flex: 1;
      margin-left: 0.1rem;
      text-align: right;
      .ant-btn {
        width: 0.64rem;
        height: 0.24rem;
        line-height: 0.24rem;
        font-size: 0.1rem;
        text-align: center;
        padding: 0;
        box-shadow: $main-box-shadow;
      }
    }
  }
}
</style>
